import React from "react"
import { graphql } from "gatsby"
import { ParsePostContentHTML } from "../../helpers/content.helpers"

import { ReactTypeformEmbed } from "react-typeform-embed"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Page = ({ data }) => {
   return (
      <Layout>
         <SEO WPPageID={data.wordpressPage.wordpress_id} />

         <div className="site page">
            <main id="main" className="container container-m">
               <h1>{data.wordpressPage.title}</h1>

               {ParsePostContentHTML(data.wordpressPage.content)}

               {data.wordpressPage.acf.typeformLinkEmbed && (
                  <ReactTypeformEmbed
                     url={data.wordpressPage.acf.typeformLinkEmbed}
                     popup={false}
                     style={{
                        position: "relative",
                        height: "500px",
                     }}
                  />
               )}
            </main>
         </div>
      </Layout>
   )
}

export default Page

export const query = graphql`
   query($id: Int!) {
      wordpressPage(wordpress_id: { eq: $id }) {
         title
         excerpt
         content
         wordpress_id
         acf {
            typeformLinkEmbed
         }
      }
   }
`
